import { RootStore } from '@/app/store'
import { fetchJSON } from '@/utils/http'
import { priorityAndTitleSorter } from '@/utils/sort'
import { makeAutoObservable, runInAction } from 'mobx'
import { API_ROUTE } from '../backend/config'
import { Category, Tool, Toolset } from './types'

export class ToolsStore {
  toolsetsLoaded = false

  tools: Tool[] = []
  toolsets: Toolset[] = []
  categories: Category[] = []
  currentTool?: Tool
  rootStore: RootStore
  lastVisitedToolset?: string

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  // NOTE: Called on logout
  public resetState() {
    this.toolsetsLoaded = false

    this.tools = []
    this.toolsets = []
    this.categories = []
    this.currentTool = undefined
    this.lastVisitedToolset = undefined
  }

  public async fetchTools() {
    const tools = await fetchJSON(`${API_ROUTE}/tools?limit=100`)
    runInAction(() => {
      this.tools = Array.isArray(tools.docs)
        ? tools.docs.filter((tool: Tool) => tool.hidden !== true)
        : []
    })
    this.updateCategories()
  }

  public async fetchToolset(slug: string) {
    const toolset = await fetchJSON(`${API_ROUTE}/toolsets/s/${slug}`)
    runInAction(() => {
      this.tools = Array.isArray(toolset.tools)
        ? toolset.tools.filter((tool: Tool) => tool.hidden !== true)
        : []
    })
    this.updateCategories()
  }

  public async fetchToolsets() {
    const data = (await fetchJSON(`${API_ROUTE}/toolsets/user`)) as {
      docs: Toolset[]
    }
    data.docs.sort(priorityAndTitleSorter)
    runInAction(() => {
      this.toolsets = data.docs.filter((toolset) => toolset.displayInSidebar)
      this.toolsetsLoaded = true
    })
  }

  public async fetchTool(id: string) {
    const tool = await fetchJSON(`${API_ROUTE}/tools/${id}`)
    runInAction(() => {
      this.currentTool = tool
    })
  }

  public updateCategories() {
    let categories: Category[] = []

    for (const tool of this.tools) {
      if (!tool.category || typeof tool.category === 'string') {
        continue
      }

      if (!categories.find((c) => c.id === (tool.category as Category).id)) {
        categories.push(tool.category)
      }
    }
    categories = [...new Set(categories)]
    categories.sort(priorityAndTitleSorter)
    this.categories = categories
  }

  public saveLastToolset(slug?: string) {
    this.lastVisitedToolset = slug
  }
}

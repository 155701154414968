import './lib/sentry'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './app/App'
import './assets/stylesheets/style.scss'
import ReactGA from 'react-ga4'

import { GA_MEASUREMENT_ID } from './features/backend/config'
import { toJS } from 'mobx'
import { SentryErrorBoundary } from './app/SentryErrorBoundary'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

ReactGA.initialize(GA_MEASUREMENT_ID)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
})

// for debugging purposes
;(window as any)['tojs'] = toJS

const stylesCache = createCache({
  key: 'css',
  nonce: 'emotion',
  prepend: true,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <QueryClientProvider client={queryClient}>
    <SentryErrorBoundary>
      <CacheProvider value={stylesCache}>
        <App />
      </CacheProvider>
    </SentryErrorBoundary>
  </QueryClientProvider>,
)

// <React.StrictMode>

import { RootStore } from '@/app/store'
import { flowResult, makeAutoObservable } from 'mobx'
import * as api from './api'
import {
  CompanyPublicInfo,
  ListMembersResponse,
  OrgDailyStats,
  PlanInfo,
} from './types'
import { logAndRethrow } from '@/utils/error'

export type EditablePlanInfo = {
  hasRAGEnabled: boolean
}

export class OrganizationsStore {
  rootStore: RootStore

  initialLoadingPlan = false
  planInfo: PlanInfo | undefined = undefined
  membersData: ListMembersResponse | undefined = undefined
  sendingMemberInvite = false

  isLoadingPublicInfo = false
  publicInfo: CompanyPublicInfo | undefined = undefined

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  // NOTE: Called on logout
  public resetState() {
    this.isLoadingPublicInfo = false
    this.planInfo = undefined
    this.membersData = undefined
    this.publicInfo = undefined
  }

  *fetchStats(interval: number) {
    const stats: OrgDailyStats[] = yield api.fetchStats(interval)
    return stats
  }

  *fetchPlan() {
    // NOTE: Initial loading state is only set if the plan info is not already loaded
    if (!this.planInfo) {
      this.initialLoadingPlan = true
    }

    const { plan, members } = yield api.fetchPlan()
    this.initialLoadingPlan = false

    this.planInfo = plan
    this.membersData = members

    return members
  }

  *inviteMember(email: string) {
    this.sendingMemberInvite = true

    yield api.inviteMember(email).finally(() => {
      this.sendingMemberInvite = false
    })
  }

  *updateMemberStatus(memberId: string, active: boolean) {
    yield api.updateMemberStatus(memberId, active)
  }

  *updatePlan(values: EditablePlanInfo) {
    const updatedPlan: { plan: PlanInfo } = yield api.updatePlan(values)

    this.planInfo = updatedPlan.plan
  }

  async autocompleteCompanyInfo(files: File[]) {
    const suggestedData = await api.autocompleteCompanyInfoFromFiles(files)

    return suggestedData
  }

  *fetchCompanyInfo() {
    this.isLoadingPublicInfo = true

    try {
      const data: CompanyPublicInfo = yield api.fetchPublicCompanyInfo()

      this.publicInfo = data
    } catch (error) {
      console.error('Error fetching company info', error)
    } finally {
      this.isLoadingPublicInfo = false
    }
  }

  async saveCompanyInfo(data: CompanyPublicInfo) {
    await api
      .updateCompanyInfo(data)
      .catch(logAndRethrow('Error saving company info'))

    await flowResult(this.fetchCompanyInfo()).catch(
      logAndRethrow('Error refreshing company info'),
    )

    await flowResult(this.fetchPlan()).catch(
      logAndRethrow('Error refreshing plan info'),
    )
  }
}

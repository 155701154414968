import rootStore from '@/app/store'
import {
  Divider,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Avatar,
} from '@mui/material'
import React from 'react'
import {
  TbActivity,
  TbBooks,
  TbLogout,
  TbPlugConnected,
  TbUser,
  TbUsersGroup,
} from 'react-icons/tb'
import { truncate } from '@/utils/string'
import { useNavigate } from 'react-router-dom'

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigate = useNavigate()

  const { auth, tools, organizations, knowledgeBases, chat, tool } = rootStore

  const handleLogout = () => {
    auth.logout()

    tools.resetState()
    chat.resetState()
    tool.resetState()
    organizations.resetState()
    knowledgeBases.resetState()

    navigate('/tools')

    handleClose()
  }

  const userRole = auth.user.role

  const navAndClose = (path: string) => {
    navigate(path)
    handleClose()
  }

  return (
    <div className="user-menu">
      <Button
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Avatar sx={{ marginRight: '10px', backgroundColor: '#006dff' }}>
          <TbUser />
        </Avatar>
        {truncate(auth.user.email.split('@')[0], 16)}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className="menu-header">{auth.user.email}</div>
        <div className="menu-header">
          <b>{auth.user.organizationName}</b>
        </div>
        <Divider />

        {/*
            {auth.user.active ? (
              <>
                <div className="menu-header">
                  Creditos: {auth.user.chatTokensLeft}
                </div>
                <Divider />
              </>
            ) : null}
        */}

        {userRole === 'org-manager'
          ? [
              <MenuItem
                key="subscriptions"
                onClick={() => navAndClose('/subscriptions')}
                sx={{ paddingTop: '10px' }}
              >
                <ListItemIcon>
                  <TbUsersGroup />
                </ListItemIcon>
                <ListItemText>Organización</ListItemText>
              </MenuItem>,
              <MenuItem
                key="knowledge-bases"
                onClick={() => navAndClose('/knowledge-bases')}
                sx={{ paddingTop: '10px' }}
              >
                <ListItemIcon>
                  <TbBooks />
                </ListItemIcon>
                <ListItemText>Base de conocimientos</ListItemText>
              </MenuItem>,
              <MenuItem
                key="org-stats"
                onClick={() => navAndClose('/stats')}
                sx={{ paddingTop: '10px' }}
              >
                <ListItemIcon>
                  <TbActivity />
                </ListItemIcon>
                <ListItemText>Estadísticas</ListItemText>
              </MenuItem>,
              <MenuItem
                key="api"
                onClick={() => navAndClose('/api')}
                sx={{ paddingTop: '10px' }}
              >
                <ListItemIcon>
                  <TbPlugConnected />
                </ListItemIcon>
                <ListItemText>API</ListItemText>
              </MenuItem>,
            ]
          : null}

        {userRole === 'admin' ? (
          <MenuItem
            key="global-knowledge-base"
            onClick={() => navigate('/knowledge-bases/global')}
            sx={{ paddingTop: '10px' }}
          >
            <ListItemIcon>
              <TbBooks />
            </ListItemIcon>
            <ListItemText>Documentos Toolkit</ListItemText>
          </MenuItem>
        ) : null}

        <MenuItem onClick={handleLogout} sx={{ paddingTop: '10px' }}>
          <ListItemIcon>
            <TbLogout />
          </ListItemIcon>
          <ListItemText>Cerrar sesión</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserMenu
